import MicroModal from 'micromodal';  // es6 module

window.addEventListener('load', event => {
    var CONFIG = require('./config.json');
    var url = CONFIG.restDomain + CONFIG.restEndpoint;
    $('#espace-pro').removeClass('is-open');
    // MicroModal.close('espace-pro');

    const config = {
        onShow: function(modal) {
            $('.header-container').hide();
            $('.footer-container').hide();
            $('.add-to-cart-container').hide();
        },
        onClose: function(modal) {
            $('.header-container').show();
            $('.footer-container').show();
            $('.add-to-cart-container').show();
        },
        // openTrigger: 'data-custom-open',
        // closeTrigger: 'data-custom-close',
        openClass: 'is-open',
        disableScroll: true,
        disableFocus: true,
        awaitOpenAnimation: false,
        awaitCloseAnimation: false,
        debugMode: true
    }

    $('.modal-back').live('click', function(e) {
        e.preventDefault();
        $('#espace-pro-grid').removeClass('hidden');
        $('#espace-pro-post').addClass('hidden');
    });

    // Déclenche la récupération des teasers des posts de l'espace pro
    $('.open-espace-pro').live('click', function(e) {
        e.preventDefault();
        MicroModal.show('espace-pro', config);
        if(!$('#espace-pro-grid').hasClass('complete')) {
            get_posts_data();
        }
    });
    // requete de récupération des posts de l'espace pro
    async function get_posts_data(espace) {
        const request = await fetch(url+'posts?espace='+espace, {
            crossDomain: true,
            headers: new Headers({
                'Authorization': 'Basic '+ btoa(CONFIG.username + ":" + CONFIG.password),
            })
        });

        const response = await request.json();
        if (request.status == 200) {
            // Request was successful
            console.log(response);
            console.log(request.status);
            $('.modal-loader').addClass('hidden');
            $('#espace-pro-grid').removeClass('hidden');
            $('#espace-pro-grid').addClass('complete');
            display_pro_posts(response);
        }
    }
    // template d'affichage des teaser
    function display_pro_posts(data) {
        if (data) {
            var html = "";
            var modal = document.getElementById('espace-pro-grid');
            for(let i = 0; i < data.length; i++) {
                let obj = data[i];
                let title = obj.title.rendered;
                let id = obj.id;
                let media = obj.featured_media;
                // var excerpt = obj.excerpt.rendered;
                let thumbnailID = obj.featured_media;
                let thumbnailSRC = "";
                html += "<div class='item'>";
                html += "<img src='' class='hidden img-"+media+"' alt=''/>";
                if (thumbnailID) {
                    $.ajax({
                        url: url+"media/"+thumbnailID,
                        type: "get",
                        dataType: 'json',
                        crossDomain: true,
                        headers: {
                            "Authorization": "Basic " + btoa(CONFIG.username + ":" + CONFIG.password)
                        },
                        error: function () {
                            console.log('error');
                        },
                        beforeSend: function () {
                            MicroModal.show('espace-pro', config);
                        },
                        complete: function () {
                            $('.modal-loader').addClass('hidden');
                            $('#espace-pro-grid').removeClass('hidden');
                            $('#espace-pro-grid').addClass('complete');
                            $('#espace-pro-content').removeClass('first-view');
                        },
                        success: function (res, status) {
                            get_thumbnails_pro_post(res);
                        }
                    });
                }
                console.log(obj);
                // html += template_post_teaser(obj);
                html += "<h2><a href='#' class='get-post-link' data-id='"+id+"'>"+title+"</a></h2>";
                // returnHTML += "<p>"+excerpt+"</p>";
                html += "</div>";
            }
            // console.log(html);
            modal.innerHTML = html;
        }
    }
    display_first_view();
    function display_first_view() {
        var html = "";
        var article = document.getElementById('espace-pro-post');
        article.innerHTML = html;
        $('.modal-loader').addClass('hidden');
        $('#espace-pro-content').addClass('first-view');
        $('#espace-pro-grid').removeClass('hidden');
        $('#espace-pro-grid').addClass('complete');
        $('#espace-pro-grid').addClass('first_view');
        var html = "<div class='item' id='evaluer'> <img src='https://www.nutrisens.com/wp-content/uploads/2024/01/1-1.png'> <h2> <a href='#'>En savoir plus</a> </h2></div>";
        html += "<div class='item' id='se_former'> <img src='https://www.nutrisens.com/wp-content/uploads/2024/01/2-1.png'> <h2> <a href='#'>En savoir plus</a> </h2></div>";
        html += "<div class='item' id='se_documenter'> <img src='https://www.nutrisens.com/wp-content/uploads/2024/01/4-1.png'> <h2> <a href='https://www.nutrisens.com/vitalite-le-mag/' target='_blank'>En savoir plus</a> </h2></div>";
        html += "<div class='item' id='nos_produits'> <img src='https://www.nutrisens.com/wp-content/uploads/2024/01/3-1.png'> <h2> <a href='#'>En savoir plus</a> </h2></div>";
        var modal = document.getElementById('espace-pro-grid');
        var article = modal.innerHTML = html;
        /**/
        document.getElementById('evaluer').addEventListener('click', function () {
            $('.modal-loader').removeClass('hidden');
            $('#espace-pro-grid').addClass('hidden');
            $('#espace-pro-grid').removeClass('complete');
            get_posts_data(418);

        });
        document.getElementById('se_former').addEventListener('click', function () {
            $('.modal-loader').removeClass('hidden');
            $('#espace-pro-grid').addClass('hidden');
            $('#espace-pro-grid').removeClass('complete');
            get_posts_data(417);
        });
        document.getElementById('se_documenter').addEventListener('click', function () {
            /*$('.modal-loader').removeClass('hidden');
            $('#espace-pro-grid').addClass('hidden');
            $('#espace-pro-grid').removeClass('complete');
            get_posts_data(416);*/
        });
        document.getElementById('nos_produits').addEventListener('click', function () {
            $('.modal-loader').removeClass('hidden');
            $('#espace-pro-grid').addClass('hidden');
            $('#espace-pro-grid').removeClass('complete');
            get_posts_data(415);
        });
    }
    document.getElementById('back_to_tax').addEventListener('click', function () {
        $('.modal-loader').removeClass('hidden');
        $('#espace-pro-grid').addClass('hidden');
        $('#espace-pro-grid').removeClass('complete');
        display_first_view();
    });
    // récupération des thumbnails des posts
    function get_thumbnails_pro_post(data) {
        console.log(data);
        if (data) {
            // thumbnailSRC = data.source_url;
            // html += "<img src='"+thumbnailSRC+"' alt=''/>";
            $('.img-'+data.id).attr('src', data.source_url);
            $('.img-'+data.id).removeClass('hidden');
        }
    }

    // déclenche la récupération du post en cliquant sur le titre
    $('.get-post-link').live('click', function(e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        $('#espace-pro-grid').addClass('hidden');
        $('.modal-loader').removeClass('hidden');
        $('#espace-pro-content').addClass('first-view');
        get_post_data(id);

    });
    // requete de récupération du post
    async function get_post_data(id) {

        var modal = document.getElementById('espace-pro-post');
        var html = "";
        const request = await fetch(url + "posts/"+id, { 
            crossDomain: true,
            headers: new Headers({
                'Authorization': 'Basic '+ btoa(CONFIG.username + ":" + CONFIG.password)
            })
        });

        const response = await request.json();
        if (request.status == 200) {
            // Request was successful
            console.log(response);
            console.log(request.status);
            html = template_post(response);
            modal.innerHTML = html;
            $('#espace-pro-grid').addClass('hidden');
            $('#espace-pro-post').removeClass('hidden');
            $('.modal-loader').addClass('hidden');
            $('#espace-pro-content').removeClass('first-view');
        }
    }
    // template d'affichage du post
    function template_post(post) {
        var title = post.title.rendered;
        // var id = post.id;
        var content = post.content.rendered;
        // var thumbnailID = post.featured_media;
        // var thumbnailSRC = "";
        var returnHTML = "";


        returnHTML += "<div>";
        returnHTML += "<div><a href='#' class='modal-back'>< Revenir aux articles</a></div>";
        // returnHTML += "<img src='"+thumbnailSRC+"' alt=''/>";
        returnHTML += "<h2>"+title+"</h2>";
        returnHTML += content;
        returnHTML += "</div>";
        // if (thumbnailID) {
        //     $.ajax({
        //         url: url + "media/" + thumbnailID,
        //         type: "get",
        //         dataType: 'json',
        //         crossDomain: true,
        //         async: false,
        //         headers: {
        //             "Authorization": "Basic " + btoa(CONFIG.username + ":" + CONFIG.password)
        //         },
        //     }).done(function(data){
        //         if (data) {
        //             thumbnailSRC = data.source_url;
        //             returnHTML += "<div>";
        //             returnHTML += "<a href='#' class='modal-back'>< retour</a>";
        //             returnHTML += "<img src='"+thumbnailSRC+"' alt=''/>";
        //             returnHTML += "<h2>"+title+"</h2>";
        //             returnHTML += content;
        //             returnHTML += "</div>";
        //         }
        //     }).fail(function(jqXHR, ajaxOptions, thrownError){
        //         console.log("fail");
        //     });
        // }

        return returnHTML;
    }

    // déclenche la récupération de la fiche tech nique
    $('.technical-link').live('click', function(e) {
        e.preventDefault();
        var ref = $(this).attr('data-reference').trim();
        get_technical_file(ref)
    })
    // requete de récupération de la fiche technique
    async function get_technical_file(ref) {
        var productQuery = CONFIG.restDomain+'/wp_query/args?post_type=products&meta_query[relation]=AND&meta_query[0][key]=reference&meta_query[0][value]='+ref+'&meta_query[0][compare]==&meta_query[1][key]=fiche_technique&meta_query[1][value]=0&meta_query[1][compare]=>';
        console.log(productQuery);
        $('.modal-loader').removeClass('hidden');
        $('#espace-pro-grid').addClass('hidden');
        $('#espace-pro-post').addClass('hidden');
        MicroModal.show('espace-pro');
        const request_post = await fetch(productQuery, { 
            crossDomain: true,
            headers: new Headers({
                'Authorization': 'Basic '+ btoa(CONFIG.username + ":" + CONFIG.password),
            })
        });
        const response_post = await request_post.json();
        if (request_post.status == 200) {
            console.log(response_post);
            if (response_post[0]) {
                let pid = response_post[0].id;
                console.log(pid);
                const request_media = await fetch(url + "products/" + pid + "?_fields=acf.fiche_technique", { 
                    crossDomain: true,
                    headers: new Headers({
                        'Authorization': 'Basic '+ btoa(CONFIG.username + ":" + CONFIG.password),
                    })
                });
                const response_media = await request_media.json();
                if (request_media.status == 200) {
                    console.log(response_media);
                    let fid = response_media.acf.fiche_technique;
                    const request_url = await fetch(url + "media/" + fid, { 
                        crossDomain: true,
                        headers: new Headers({
                            'Authorization': 'Basic '+ btoa(CONFIG.username + ":" + CONFIG.password),
                        })
                    });
                    const response_url = await request_url.json();
                    if (request_url.status == 200) {
                        if (response_url) {
                            console.log(response_url);
                            $('.modal-loader').addClass('hidden');
                            $('#espace-pro-grid').removeClass('hidden');
                            MicroModal.close('espace-pro', config);
                            if (response_url.source_url) {
                                window.open(response_url.source_url, "_blank");
                            } else {
                                alert("Fiche techique introuvable");
                            }
                        }
                    }
                }
            }  else {
                alert("Fiche techique introuvable");
                $('.modal-loader').addClass('hidden');
                $('#espace-pro-grid').removeClass('hidden');
                MicroModal.close('espace-pro', config);
            }
            
        }
    }

})